import React, { useEffect, useState } from "react";
import "../styles/customers.css";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { useNavigate } from "react-router-dom";
import { IconButton, Button } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PublicSharpIcon from "@mui/icons-material/PublicSharp";
import { db } from "../firebase"; // Import Firestore
import { collection, query, orderBy, onSnapshot, deleteDoc, doc, updateDoc } from "firebase/firestore";
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import EditNoteIcon from '@mui/icons-material/EditNote';
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import axios from 'axios';
const Customers = () => {
  const navigate = useNavigate();
  const [customers, setCustomers] = useState([]);
  const [editId, setEditId] = useState(null); // Track which row is being edited
  const [editedCustomer, setEditedCustomer] = useState({}); // Store edited data
  const [isDark, setIsDark] = useState(false); // State for dark background effect
  const [showProfileOverlay, setShowProfileOverlay] = useState(false); // State for profile overlay
  
  useEffect(() => {
    axios.get('http://localhost:5000/api/customers')
    .then(response => {
      setCustomers(response.data);
    })
    .catch(error => {
      console.error('There was an error fetching the customer data!', error);
    });

    const q = query(collection(db, "customers"), orderBy("createdAt", "desc"));
    const unsubscribe = onSnapshot(q, (snapshot) => {
      const customerList = snapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setCustomers(customerList);
    });

    return () => unsubscribe();
  }, []);

  const handleGoBack = () => {
    navigate("/sales");
  };

  const handleProfileClick = () => {
    setShowProfileOverlay(!showProfileOverlay);
    setIsDark(!isDark); // Toggle dark background when profile is clicked
  };

  const handleAddCustomer = () => {
    navigate("/addcustomer");
  };

  const handleBulkUpload = () => {
    navigate("/bulkupload");
  };

  const handleDelete = async (id) => {
    if (window.confirm("Are you sure you want to delete this customer?")) {
      try {
        await deleteDoc(doc(db, "customers", id));
        setCustomers(customers.filter((customer) => customer.id !== id));
      } catch (error) {
        console.error("Error deleting customer:", error);
        alert("Failed to delete the customer. Please try again.");
      }
    }
  };

  const handleEdit = (customer) => {
    setEditId(customer.id); // Set the row to be edited
    setEditedCustomer(customer); // Copy the customer data to edit
  };

  const handleInputChange = (field, value) => {
    setEditedCustomer((prev) => ({ ...prev, [field]: value }));
  };

  const handleSave = async (id) => {
    try {
      const customerRef = doc(db, "customers", id);
      await updateDoc(customerRef, editedCustomer); // Update Firestore
      setCustomers((prev) =>
        prev.map((customer) =>
          customer.id === id ? { ...customer, ...editedCustomer } : customer
        )
      );
      alert("Customer updated successfully.");
      setEditId(null); // Exit edit mode
    } catch (error) {
      console.error("Error updating customer:", error);
      alert("Failed to update the customer. Please try again.");
    }
  };

  return (
    <div className="customers-page">
      <main className="main-content">
        <header className="customer">
          <h1>CUSTOMERS</h1>
          <IconButton onClick={handleGoBack} color="white" aria-label="go back">
            <ArrowCircleLeftOutlinedIcon
              className="arrowback-customer"
              fontSize="larger" /* or "small", "", "medium" */
            />
          </IconButton>
          <div className="header-search">
            <input type="text" placeholder="Search for something" />
            <PublicSharpIcon
              className="public-customer"
              fontSize="larger" /* or "small", "", "medium" */
            />
            <NotificationsIcon
              className="notification-customer"
              fontSize="larger" /* or "small", "", "medium" */
              aria-label="notifications"
            />
            {/* Profile Icon */}
            <img
              src="./profile.png"
              className="profile-customer"
              alt="profile"
              onClick={handleProfileClick}
            />
            {/* Profile Overlay */}
            {showProfileOverlay && (
              <div className="profile-overlay">
                <div className="view-profile">
                  <CancelTwoToneIcon
                    onClick={handleGoBack}
                    sx={{
                      position: "absolute",
                      bottom: "295px",
                      right: "5px",
                      color: "red",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  />
                  <img src="./dp.png" alt="dp"></img>
                  <b>EZBillify</b>
                  <p>GSTIN:2964A78DL102865</p>
                  <hr />
                  <SettingsRoundedIcon
                    sx={{ position: "relative", right: "85px", top: "55px", color: "black", fontSize: "25px" }}
                  />
                  <ManageAccountsRoundedIcon
                    sx={{ position: "relative", right: "110px", top: "20px", color: "black", fontSize: "25px" }}
                  />
                  <PersonAddAltRoundedIcon
                    sx={{ position: "relative", right: "132px", top: "89px", color: "black", fontSize: "25px" }}
                  />
                  <div className="dp-heading">
                    <a onClick={() => navigate("/Business")}>BUSINESS<c>DETAILS</c></a>
                    <d onClick={() => navigate("/settings")}>SETTINGS</d>
                    <e onClick={() => navigate("/AddUsers")}>ADD <f>USERS</f></e>
                  </div>
                </div>
              </div>
            )}
          </div>
        </header>

        <div className="button-section" style={{ marginTop: "25px", marginLeft: "60%", textAlign: "center" }}>
          <Button
            variant="contained"
            sx={{
              position: "absolute",
              right: "350px",
              top: "170px",
              backgroundColor: "white",
              color: "black",
              width: "250px",
              height: "50px",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
            onClick={handleAddCustomer}
          >
            <b>+</b> Add Customer
          </Button>
          <Button
            variant="contained"
            onClick={handleBulkUpload}
            sx={{
              position: "absolute",
              right: "65px",
              top: "170px",
              width: "250px",
              height: "50px",
              backgroundColor: "white",
              color: "black",
              borderRadius: "10px",
              boxShadow: "none",
              "&:hover": {
                backgroundColor: "white",
                color: "black",
                boxShadow: "none",
              },
            }}
          >
            Bulk Upload
          </Button>
        </div>

        <div className="customer-list">
          <table className="customer-table">
            <thead>
              <tr>
                <th>Name</th>
                <th>Address</th>
                <th>Pincode</th>
                <th>Phone</th>
                <th>Email</th>
                <th>GST Number</th>
                <th>Company Name</th>
                <th>Functions</th>
              </tr>
            </thead>
            <tbody>
              {customers.map((customer) => (
                <tr key={customer.id}>
                  <td>
                    {editId === customer.id ? (
                      <input
                        type="text"
                        value={editedCustomer.name || ""}
                        onChange={(e) => handleInputChange("name", e.target.value)}
                      />
                    ) : (
                      customer.name
                    )}
                  </td>
                  <td>
                    {editId === customer.id ? (
                      <>
                        <input
                          type="text"
                          value={editedCustomer.address || ""}
                          onChange={(e) => handleInputChange("address", e.target.value)}
                          placeholder="address"
                        />
                        <input
                          type="text"
                          value={editedCustomer.addressLine2 || ""}
                          onChange={(e) => handleInputChange("addressLine2", e.target.value)}
                          placeholder="addressLine2"
                        />
                      </>
                    ) : (
                      // Concatenate both address lines when not editing
                      `${customer.address || ""} ${customer.addressLine2 || ""}`.trim()
                    )}
                  </td>

                  <td>
                    {editId === customer.id ? (
                      <input
                        type="text"
                        value={editedCustomer.pincode || ""}
                        onChange={(e) => handleInputChange("pincode", e.target.value)}
                      />
                    ) : (
                      customer.pincode
                    )}
                  </td>
                  <td>
                    {editId === customer.id ? (
                      <input
                        type="text"
                        value={editedCustomer.phoneNumber || ""}
                        onChange={(e) => handleInputChange("phoneNumber", e.target.value)}
                      />
                    ) : (
                      customer.phoneNumber // This should reflect the phone number from Firestore
                    )}
                  </td>

                  <td>
                    {editId === customer.id ? (
                      <input
                        type="text"
                        value={editedCustomer.email || ""}
                        onChange={(e) => handleInputChange("email", e.target.value)}
                      />
                    ) : (
                      customer.email
                    )}
                  </td>
                  <td>
                    {editId === customer.id ? (
                      <input
                        type="text"
                        value={editedCustomer.gstNumber || ""}
                        onChange={(e) => handleInputChange("gstNumber", e.target.value)}
                      />
                    ) : (
                      customer.gstNumber
                    )}
                  </td>
                  <td>
                    {editId === customer.id ? (
                      <input
                        type="text"
                        value={editedCustomer.companyName || ""}
                        onChange={(e) => handleInputChange("companyName", e.target.value)}
                      />
                    ) : (
                      customer.companyName
                    )}
                  </td>
                  <td>
                    {editId === customer.id ? (
                      <button onClick={() => handleSave(customer.id)}>Save</button>
                    ) : (
                      <button onClick={() => handleEdit(customer)}>Edit</button>
                    )}
                    <DeleteForeverIcon
                      onClick={() => handleDelete(customer.id)}
                      className="delete-icon"
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </main>
    </div>
  );
};

export default Customers;
