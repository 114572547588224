import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import "../styles/masterdata.css"; // Link your custom styles
import NotificationsIcon from "@mui/icons-material/Notifications";
import PublicSharpIcon from "@mui/icons-material/PublicSharp";
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import styled from 'styled-components';
const Dashboard = () => {
  const navigate = useNavigate();
  const [showProfileOverlay, setShowProfileOverlay] = useState(false); // State for profile overlay
  const [isDark, setIsDark] = useState(false); // State for dark background effect
  const [searchQuery, setSearchQuery] = useState(""); // State for search query
  const [suggestions, setSuggestions] = useState([]); // State for search suggestions

  // List of possible pages/routes
  const pages = [
   "dashboard",
   "sales",
   "items",
   "purchase",
   "masterdata",
   "accounting",
   "gstreturns",
   "others",
   "settings",
   "business-details",
   ];

  const handleProfileClick = () => {
    setShowProfileOverlay(!showProfileOverlay);
    setIsDark(!isDark); // Toggle dark background when profile is clicked
  };

  // Updated handleGoBack to not navigate away from the Dashboard
  const handleGoBack = () => {
    setShowProfileOverlay(false); // Just close the profile overlay
    setIsDark(false); // Optionally reset dark background
  };

// Handle search input change
const handleSearchChange = (e) => {
  const query = e.target.value;
  setSearchQuery(query);

  // Filter pages based on the search query
  if (query.trim() === "") {
    setSuggestions([]); // Clear suggestions if the input is empty
  } else {
    const filteredSuggestions = pages.filter((page) =>
      page.toLowerCase().startsWith(query.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
  }
};

// Handle search on Enter key press
const handleSearch = (event) => {
  if (event.key === "Enter" && searchQuery.trim()) {
    // Navigate to the first matched page
    switch (searchQuery.toLowerCase()) {
      case "sales":
        navigate("/sales");
        break;
      case "dashboard":
        navigate("/dashboard");
        break;
      case "purchase":
        navigate("/purchase");
        break;
      case "accounting":
        navigate("/accounting");
        break;
      case "gstreturns":
        navigate("/gstreturns");
        break;
      case "settings":
        navigate("/settings");
        break;
      case "items":
        navigate("/items");
        break;
      case "masterdata":
        navigate("/masterdata");
        break;
      case "business-details":
        navigate("/business-details");
        break;
        case "others":
        navigate("/others");
        break;
      default:
        alert("Page not found!");
        break;
    }
  }
};
  return (
    <body>
    <div className="masterdata-container">
   
    
      {/* Main Content */}
      <main className="masterdata-content">
        <header className="masterdata-header">
          <h1>MASTER DATA</h1>
          <div className="masterdata-actions1">
         
          <StyledWrapper>
      <div className="searchBox">
        <input className="searchInput" type="text" name placeholder="Search something"  value={searchQuery}
              onChange={handleSearchChange} // Track search input
              onKeyDown={handleSearch}/>
        <button className="searchButton" href="#">
          <svg xmlns="http://www.w3.org/2000/svg" width={29} height={29} viewBox="0 0 29 29" fill="none">
            <g clipPath="url(#clip0_2_17)">
              <g filter="url(#filter0_d_2_17)">
                <path d="M23.7953 23.9182L19.0585 19.1814M19.0585 19.1814C19.8188 18.4211 20.4219 17.5185 20.8333 16.5251C21.2448 15.5318 21.4566 14.4671 21.4566 13.3919C21.4566 12.3167 21.2448 11.252 20.8333 10.2587C20.4219 9.2653 19.8188 8.36271 19.0585 7.60242C18.2982 6.84214 17.3956 6.23905 16.4022 5.82759C15.4089 5.41612 14.3442 5.20435 13.269 5.20435C12.1938 5.20435 11.1291 5.41612 10.1358 5.82759C9.1424 6.23905 8.23981 6.84214 7.47953 7.60242C5.94407 9.13789 5.08145 11.2204 5.08145 13.3919C5.08145 15.5634 5.94407 17.6459 7.47953 19.1814C9.01499 20.7168 11.0975 21.5794 13.269 21.5794C15.4405 21.5794 17.523 20.7168 19.0585 19.1814Z" stroke="white" strokeWidth={3} strokeLinecap="round" strokeLinejoin="round" shapeRendering="crispEdges" />
              </g>
            </g>
            <defs>
              <filter id="filter0_d_2_17" x="-0.418549" y="3.70435" width="29.7139" height="29.7139" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                <feFlood floodOpacity={0} result="BackgroundImageFix" />
                <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                <feOffset dy={4} />
                <feGaussianBlur stdDeviation={2} />
                <feComposite in2="hardAlpha" operator="out" />
                <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_2_17" />
                <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_2_17" result="shape" />
              </filter>
              <clipPath id="clip0_2_17">
                <rect width="28.0702" height="28.0702" fill="white" transform="translate(0.403503 0.526367)" />
              </clipPath>
            </defs>
          </svg>
        </button>
      </div>
    </StyledWrapper>
            {/* Show suggestions if there are any */}
            {suggestions.length > 0 && (
              <div className="suggestions-dropdown">
                {suggestions.map((suggestion, index) => (
                  <div
                    key={index}
                    className="suggestion-item"
                    onClick={() => {
                      // Navigate to the selected page when clicked
                      navigate(`/${suggestion}`);
                      setSearchQuery(""); // Clear search input after selection
                      setSuggestions([]); // Clear suggestions
                    }}
                  >
                    {suggestion}
                  </div>
                ))}
              </div>
            )} 
            <div className="icons">
            <PublicSharpIcon
            className="public"
            fontSize="larger" /* or "small", "", "medium" */
            
            />
            <NotificationsIcon
              className="notification"
              fontSize="larger" /* or "small", "", "medium" */  
           
              aria-label="notifications"
            />
           {/* Profile Icon */}
           <img
              src="./profile.png"
            className="profile"
              alt="profile"
            
              onClick={handleProfileClick}
            />

              {/* Profile Overlay */}
              {showProfileOverlay && (
                <div className="profile-overlay">
                  <div className="view-profile">
                    {/* Cancel icon now only closes the profile overlay */}
                    <CancelTwoToneIcon
                      onClick={handleGoBack}
                      sx={{
                        position: "absolute",
                        bottom: "295px",
                        right: "5px",
                        color: "red",
                        fontSize: "20px",
                        cursor: "pointer",
                      }}
                    />
                    <img src="./dp.png" alt="dp"></img>
                    <b>EZBillify</b>
                    <p>GSTIN:2964A78DL102865</p>
                    <hr/>
                 <SettingsRoundedIcon 
                  sx={{position:"relative",right:"85px",top:"55px",color:"black",fontSize:"25px"}} />
                   <ManageAccountsRoundedIcon 
                  sx={{position:"relative",right:"110px",top:"20px",color:"black",fontSize:"25px"}} />
                   <PersonAddAltRoundedIcon 
                 sx={{position:"relative",right:"132px",top:"89px",color:"black",fontSize:"25px"}} />
                  <div className="dp-heading">
                    <a  onClick={() => navigate("/Business")}>BUSINESS<c >DETAILS</c> </a>
                    <d  onClick={() => navigate("/settings")} >SETTINGS</d>
                    <e  onClick={() => navigate("/AddUsers")}>  ADD <f>USERS</f></e>
                  </div>
                  
                  </div>
                </div>
              )}
            </div>
          </div>
        </header>
        {/* Buttons */}
        <section className="functional-btn-masterdata1">
            <img src="./bank-account.png" alt="Robot" onClick={() => navigate("/bankaccount")}/>
            <img src="./customer-groups.png" alt="Robot"  onClick={() => navigate("/customergroups")}  />
            <img src="./expenses-type.png" alt="Robot" onClick={() => navigate("/expensestype")}/>
            <img src="./item-category.png" alt="Robot" onClick={() => navigate("/itemcategory")}/>
            <img  src="./unit-master.png" alt="Robot" onClick={() => navigate("/unitmaster")} />
            <img src="./employee.png" alt="Robot"onClick={() => navigate("/employee")} />
        </section>

      </main>
    </div>
    </body>
  );
};

const StyledWrapper = styled.div`
  .searchBox {
    display: flex;
    max-width: 350px;
    max-height:60px;
    align-items: center;
    justify-content: space-between;
    gap: 8px;
    background: #2f3640;
    border-radius: 50px;
    position: relative;
    bottom:57px;
    left:700px;
  }

  .searchButton {
    color: white;
    position: absolute;
    right: 8px;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: var(--gradient-2, linear-gradient(90deg, #2AF598 0%, #009EFD 100%));
    border: 0;
    display: inline-block;
    transition: all 300ms cubic-bezier(.23, 1, 0.32, 1);
  }
  /*hover effect*/
  button:hover {
    color: #fff;
    background-color: #1A1A1A;
    box-shadow: rgba(0, 0, 0, 0.5) 0 10px 20px;
    transform: translateY(-3px);
  }
  /*button pressing effect*/
  button:active {
    box-shadow: none;
    transform: translateY(0);
  }

  .searchInput {
    border: none;
    background: none;
    outline: none;
    color: white;
    font-size: 20px;
    padding: 24px 46px 24px 26px;
  }`;

export default Dashboard;
