import React, { useState, useEffect } from "react";
import { auth } from "../firebase";
import { signInWithEmailAndPassword, onAuthStateChanged } from "firebase/auth";
import { useNavigate } from "react-router-dom";
import "../styles/Login.css"; // You can use this for additional custom styling

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [buttonPosition, setButtonPosition] = useState({ top: "50%", left: "50%" });
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        navigate("/dashboard"); // Redirect to dashboard if logged in
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // Effect to reset button position when email and password are filled
  useEffect(() => {
    if (email && password) {
      setButtonPosition({ top: "50%", left: "50%" }); // Original position
    }
  }, [email, password]);

  const fetchCustomers = async (token) => {
    try {
      const response = await fetch("http://localhost:5000/api/customers", {
        method: "GET",
        headers: {
          "Authorization": `Bearer ${token}`, // Send token in the Authorization header
          "Content-Type": "application/json",
        },
      });

      if (response.ok) {
        const customers = await response.json();
        console.log(customers); // Display customers data from backend
      } else {
        console.error("Failed to fetch customers:", response.status);
      }
    } catch (error) {
      console.error("Error fetching customers:", error);
    }
  };

  const signIn = (email, password) => {
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        const user = userCredential.user;
        const firebaseUid = user.uid; // Get the Firebase UID
        
        // Store the Firebase UID in local storage
        localStorage.setItem('firebaseUid', firebaseUid);

        // Get the Firebase ID token
        user.getIdToken().then((token) => {
          // Fetch customers data based on the Firebase UID token
          fetchCustomers(token);
        });

        // Redirect to dashboard after login
        navigate("/dashboard");
      })
      .catch((error) => {
        console.error("Error during login: ", error);
        alert("Login failed. Please check your credentials.");
      });
  };

  const handleLogin = (e) => {
    e.preventDefault();
    // Call signIn method
    signIn(email, password);
  };

  const moveButton = () => {
    if (!email || !password) {
      const newTop = Math.random() * 80 + 10; // Randomize top position
      const newLeft = Math.random() * 80 + 10; // Randomize left position
      setButtonPosition({ top: `${newTop}%`, left: `${newLeft}%` });
    }
  };

  return (
    <body>
      <div className="login-page">
        <div className="login-container">
          {/* Left Section: Login Form */}
          <div className="login-form">
            <div className="logo">
              <img
                className="logo1"
                src="./logo.png"
                width="200px"
                height="40px"
                alt="logo"
              />
            </div>
            <h2 className="text-primary">WELCOME TO EZBILLIFY</h2>
            <p className="text-muted">Your Admin Panel</p>
            <form onSubmit={handleLogin}>
              <div className="mb-3">
                <label htmlFor="email" className="form-label">
                  Username
                </label>
                <input
                  type="email"
                  id="email"
                  className="form-control"
                  placeholder="Enter your email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
              </div>
              <div className="mb-3">
                <label htmlFor="password" className="form-label">
                  Password
                </label>
                <input
                  type="password"
                  id="password"
                  className="form-control"
                  placeholder="Enter your password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                  required
                />
              </div>
              <div className="form">
                <label htmlFor="remember" className="check">
                  <input
                    id="remember"
                    type="checkbox"
                    className="form-check-input"
                    value="Remember this Device"
                  />
                  Remember this Device
                </label>
                <a href="/forgot-password" className="forgot">
                  Forgot Password?
                </a>
              </div>
              <button
                type="submit"
                className="login-btn"
                /*NAUGHTY BUTTON  DISABLED OR ENABLE */
                /*   style={{
                      position: "absolute",
                      top: buttonPosition.top,
                      left: buttonPosition.left,
                      width:"30%",
                      height:"40px",
                      fontSize:"20px",
                      transform: "translate(-150%, 350%)",
                    }}
                    onMouseEnter={moveButton}*/
              >
                Sign In
              </button>
            </form>
            <p className="create-account">
              New to Ezbillify?{" "}
              <a href="/register" className="text-primary">
                Contact Us
              </a>
            </p>
          </div>

          {/* Right Section: Illustration */}
          <div className="login-illustration">
            <img
              src="./Robot.png"
              alt="Illustration"
              style={{ maxWidth: "110%" }}
            />
          </div>
        </div>
      </div>
    </body>
  );
};

export default Login;
