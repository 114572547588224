import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Register from "./components/Register";
import ProtectedRoute from "./components/ProtectedRoute";
import Layout from "./components/Layout"; // Import the Layout wrapper
import Dashboard from "./components/dashboard";
import Sales from "./components/sales";
import Items from "./components/items";
import Purchase from "./components/purchase";
import MasterData from "./components/masterdata";
import Accounting from "./components/accounting";
import GstReturns from "./components/gstreturns";
import Others from "./components/others";
import Prints from "./components/prints";
import Reports from "./components/reports";
import Payroll from "./components/payroll";
import Attendance from "./components/attendance";
import Manageemployee from "./components/manageemployee";
import Customers  from "./components/customers";
import Invoice  from "./components/invoice";
import Einvoice  from "./components/einvoice";
import Ewaybill  from "./components/ewaybill";
import Salesorder  from "./components/salesorder";
import Deliverychallan  from "./components/deliverychallan";
import Creditnote from "./components/creditnote";
import Paymentdue  from "./components/paymentdue";
import Paymentreceived from "./components/paymentreceived";
import Customerledger  from "./components/customerledger";
import Itemlist from "./components/itemlist";
import Viewcurrentstock from "./components/viewcurrentstock";
import Stockadjustment from "./components/stockadjustment";
import Suppliers from "./components/suppliers";
import Purchaseorder from "./components/purchaseorder";
import Purchaseinvoice from "./components/purchaseinvoice";
import Debitnote from "./components/debitnote";
import Expenses from "./components/expenses";
import Paymentreceipt from "./components/paymentreceipt";
import Payables from "./components/payables";
import Addcustomer from "./components/addcustomer";
import Bulkupload from "./components/bulkupload";
import Quotations from "./components/quotations";
import Newinvoice from "./components/newinvoice";
import Newsalesorder from "./components/newsalesorder";
import Additems from "./components/additems";
import Newdelliverychallan from "./components/newdelliverychallan";
import Employee from "./components/employee";
import Bankaccount from "./components/bankaccount";
import Customergroups from "./components/customergroups";
import Expensestype from "./components/expensestype";
import Itemcategory from "./components/itemcategory";
import Unitmaster from "./components/unitmaster";
const App = () => {
  return (
    <Router>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/register" element={<Register />} />

        {/* Protected routes wrapped with Layout */}
        <Route
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Layout> {/* Layout which is used for sidebar come to this page  */}
                <Dashboard />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/sales"
          element={
            <ProtectedRoute>
              <Layout>
                <Sales />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/items"
          element={
            <ProtectedRoute>
              <Layout>
                <Items />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/purchase"
          element={
            <ProtectedRoute>
              <Layout>
                <Purchase />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/masterdata"
          element={
            <ProtectedRoute>
              <Layout>
                <MasterData />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/accounting"
          element={
            <ProtectedRoute>
              <Layout>
                <Accounting />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/gstreturns"
          element={
            <ProtectedRoute>
              <Layout>
                <GstReturns />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/others"
          element={
            <ProtectedRoute>
              <Layout>
                <Others />
              </Layout>
            </ProtectedRoute>
          }
        />
        <Route
          path="/prints"
          element={
            <ProtectedRoute>
          
                <Prints />
       
            </ProtectedRoute>
          }
        />
        <Route
          path="/reports"
          element={
            <ProtectedRoute>
        
                <Reports />
      
            </ProtectedRoute>
          }
        />
        <Route
          path="/payroll"
          element={
            <ProtectedRoute>
               <Payroll />
            </ProtectedRoute>
          }
        />
        <Route
          path="/attendance"
          element={
            <ProtectedRoute>
             
                <Attendance />
            </ProtectedRoute>
          }
        />
        <Route
          path="/manageemployee"
          element={
            <ProtectedRoute>
             
                <Manageemployee />
              
            </ProtectedRoute>
          }
        />
        <Route
          path="/customers"
          element={
            <ProtectedRoute>
             
                <Customers />
           
            </ProtectedRoute>
          }
        />
        <Route
          path="/invoice"
          element={
            <ProtectedRoute>
              
                <Invoice />
              
            </ProtectedRoute>
          }
        />
        <Route
          path="/einvoice"
          element={
            <ProtectedRoute>
             
                <Einvoice />
             
            </ProtectedRoute>
          }
        />
        <Route
          path="/ewaybill"
          element={
            <ProtectedRoute>
             
                <Ewaybill />
             
            </ProtectedRoute>
          }
        />
        <Route
          path="/salesorder"
          element={
            <ProtectedRoute>
            
                <Salesorder />
            
            </ProtectedRoute>
          }
        />
        <Route
          path="/deliverychallan"
          element={
            <ProtectedRoute>
             
                <Deliverychallan />
            
            </ProtectedRoute>
          }
        />
        <Route
          path="/creditnote"
          element={
            <ProtectedRoute>
              
                <Creditnote />
          
            </ProtectedRoute>
          }
        />
        <Route
          path="/paymentdue"
          element={
            <ProtectedRoute>
           
                <Paymentdue />
           
            </ProtectedRoute>
          }
        />
        <Route
          path="/paymentreceived"
          element={
            <ProtectedRoute>
              
                <Paymentreceived />
              
            </ProtectedRoute>
          }
        />
        <Route
          path="/customerledger"
          element={
            <ProtectedRoute>
             
                <Customerledger />
            
            </ProtectedRoute>
          }
        />
          <Route
          path="/itemlist"
          element={
            <ProtectedRoute>
             
                <Itemlist />
        
            </ProtectedRoute>
          }
        />
        <Route
          path="/viewcurrentstock"
          element={
            <ProtectedRoute>
            
                <Viewcurrentstock />
          
            </ProtectedRoute>
          }
        />
        <Route
          path="/stockadjustment"
          element={
            <ProtectedRoute>
                <Stockadjustment />
            </ProtectedRoute>
          }
        />
        <Route
          path="/suppliers"
          element={
            <ProtectedRoute>
                <Suppliers />
            </ProtectedRoute>
          }
        />
        <Route
          path="/purchaseorder"
          element={
            <ProtectedRoute>
                <Purchaseorder />
            </ProtectedRoute>
          }
        />
        <Route
          path="/purchaseinvoice"
          element={
            <ProtectedRoute>
                <Purchaseinvoice />
            </ProtectedRoute>
          }
        />
        <Route
          path="/debitnote"
          element={
            <ProtectedRoute>
                <Debitnote />
            </ProtectedRoute>
          }
        />
        <Route
          path="/expenses"
          element={
            <ProtectedRoute>
                <Expenses />
            </ProtectedRoute>
          }
        />
        <Route
          path="/paymentreceipt"
          element={
            <ProtectedRoute>
                <Paymentreceipt />
            </ProtectedRoute>
          }
        />
        <Route
          path="/payables"
          element={
            <ProtectedRoute>
                <Payables />
            </ProtectedRoute>
          }
        />
         <Route
          path="/addcustomer"
          element={
            <ProtectedRoute>
                <Addcustomer />
            </ProtectedRoute>
          }
        />
          <Route
          path="/quotations"
          element={
            <ProtectedRoute>
                <Quotations />
            </ProtectedRoute>
          }
        />
        <Route
          path="/newinvoice"
          element={
            <ProtectedRoute>
                <Newinvoice />
            </ProtectedRoute>
          }
        />
         <Route
          path="/bulkupload"
          element={
            <ProtectedRoute>
                <Bulkupload />
            </ProtectedRoute>
          }
        />
         <Route
          path="/newsalesorder"
          element={
            <ProtectedRoute>
                <Newsalesorder />
            </ProtectedRoute>
          }
        />
          <Route
          path="/additems"
          element={
            <ProtectedRoute>
                <Additems />
            </ProtectedRoute>
          }
        />
          <Route
          path="/newdelliverychallan"
          element={
            <ProtectedRoute>
                <Newdelliverychallan />
            </ProtectedRoute>
          }
        />
            <Route
          path="/bankaccount.js"
          element={
            <ProtectedRoute>
                <Bankaccount />
            </ProtectedRoute>
          }
        />
            <Route
          path="/customergroups"
          element={
            <ProtectedRoute>
                <Customergroups />
            </ProtectedRoute>
          }
        />
            <Route
          path="/expensestype"
          element={
            <ProtectedRoute>
                <Expensestype />
            </ProtectedRoute>
          }
        />
            <Route
          path="/itemcategory"
          element={
            <ProtectedRoute>
                <Itemcategory />
            </ProtectedRoute>
          }
        />
            <Route
          path="/unitmaster"
          element={
            <ProtectedRoute>
                <Unitmaster />
            </ProtectedRoute>
          }
        />
            <Route
          path="/employee"
          element={
            <ProtectedRoute>
                <Employee />
            </ProtectedRoute>
          }
        />
        {/* Default route */}
        <Route path="/" element={<Login />} />
      </Routes>
    </Router>
  );
};

export default App;
