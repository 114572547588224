import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import axios from "axios"; // For API requests
import { getFirestore, collection, addDoc } from 'firebase/firestore';
// Firebase Configuration
const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);
// Initialize Firestore
const db = getFirestore(app);
export { db, addDoc, collection };
// Function to get the current user and their UID
export const getCurrentUser = () => {
  return auth.currentUser;
};

// Function to add customer data to MySQL via the backend
export const addCustomer = async (customerData) => {
  try {
    const user = getCurrentUser();
    if (!user) {
      console.log("User must be logged in to add customer data.");
      return;
    }

    // Add Firebase UID to customer data to associate with the authenticated user
    const customerWithUserId = { ...customerData, userId: user.uid };

    // Send a POST request to the backend API (replace with your actual backend URL)
    const response = await axios.post('http://localhost:5000/api/customers', customerWithUserId, {
      headers: {
        'Authorization': `Bearer ${await user.getIdToken()}`, // Attach Firebase token for authentication
      },
    });

    console.log("Customer added with ID:", response.data.customerId);
    return response.data.customerId; // Returning the ID of the newly added customer
  } catch (error) {
    console.error("Error adding customer:", error);
  }
};

// Example: Function to save payment type (to Firestore, if needed)
export const savePaymentType = async (paymentType) => {
  try {
    const docRef = await addDoc(collection(db, "paymentTypes"), { type: paymentType });
    console.log("Payment type saved with ID:", docRef.id);
    return docRef.id;
  } catch (error) {
    console.error("Error saving payment type:", error);
  }
};

export { auth }; // Export auth for use in your components
