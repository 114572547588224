import React, { useState, useEffect } from "react";
import "../styles/addcustomer.css";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import { useNavigate } from "react-router-dom";
import { IconButton, Button, TextField, RadioGroup, FormControlLabel, Radio } from "@mui/material";
import NotificationsIcon from "@mui/icons-material/Notifications";
import PublicSharpIcon from "@mui/icons-material/PublicSharp";
import AccountCircleOutlinedIcon from "@mui/icons-material/AccountCircleOutlined";
import { addCustomer } from "../firebase"; // Import the addCustomer function from firebase.js
import CancelTwoToneIcon from '@mui/icons-material/CancelTwoTone';
import SettingsRoundedIcon from '@mui/icons-material/SettingsRounded';
import PersonAddAltRoundedIcon from '@mui/icons-material/PersonAddAltRounded';
import ManageAccountsRoundedIcon from '@mui/icons-material/ManageAccountsRounded';
import 'react-phone-number-input/style.css'; // Add this for styles
import { verifyGST } from "./gstverification";
import axios from 'axios';
const countries = { 
  India: ["Andhra Pradesh", "Arunachal Pradesh", "Assam", "Bihar", "Chhattisgarh", "Goa", "Gujarat", "Haryana", "Himachal Pradesh", "Jharkhand", "Karnataka", "Kerala", "Madhya Pradesh", "Maharashtra", "Manipur", "Meghalaya", "Mizoram", "Nagaland", "Odisha", "Punjab", "Rajasthan", "Sikkim", "Tamil Nadu", "Telangana", "Tripura", "Uttar Pradesh", "Uttarakhand", "West Bengal", "Andaman and Nicobar Islands", "Chandigarh", "Dadra and Nagar Haveli and Daman and Diu", "Lakshadweep", "Delhi", "Puducherry"],
  USA: ["Alabama", "Alaska", "Arizona", "Arkansas", "California", "Colorado", "Connecticut", "Delaware", "Florida", "Georgia", "Hawaii", "Idaho", "Illinois", "Indiana", "Iowa", "Kansas", "Kentucky", "Louisiana", "Maine", "Maryland", "Massachusetts", "Michigan", "Minnesota", "Mississippi", "Missouri", "Montana", "Nebraska", "Nevada"],
  Canada: ["Ontario", "Quebec", "British Columbia", "Alberta"],
};

const Addcustomer = () => {
  const navigate = useNavigate();
  const [customerType, setCustomerType] = useState("Business");
  const [isDark, setIsDark] = useState(false);
  const [showProfileOverlay, setShowProfileOverlay] = useState(false);
  const [gstNumber, setGstNumber] = useState('');
  const [businessDetails, setBusinessDetails] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [rawResponse, setRawResponse] = useState(null);
  const [availableStates, setAvailableStates] = useState([]);
  const [isGstValid, setIsGstValid] = useState(true);

  const [customer, setCustomer] = useState({
    name: '',
    companyName: '',
    phoneNumber: '',
    email: '',
    gstNumber: '',
    creditInvoices: '',
    addressLine1: '',
    addressLine2: '',
    state: '',
    country: '',
    pincode: '',
    landmark: ''
  });

  const [customerDetails, setCustomerDetails] = useState({
    name: '',
    phoneNumber: '',
    email: '',
    creditInvoices: '',
    addressLine1: '',
    addressLine2: '',
    state: '',
    country: '',
    pincode: '',
    landmark: ''
  });
 


// Handling change for both customer types
const handleChange = (e) => {
  const { name, value } = e.target;
  if (customerType === "Business") {
    setCustomer({ ...customer, [name]: value });
  } else {
    setCustomerDetails({ ...customerDetails, [name]: value });
  }
};

// Handling country change and updating available states
useEffect(() => {
  if (customer.country && countries[customer.country]) {
    setAvailableStates(countries[customer.country]);
  }
}, [customer.country]);

const handleProfileClick = () => {
  setShowProfileOverlay(!showProfileOverlay);
  setIsDark(!isDark);
};

const handleSave = async () => {
  const customerData = customerType === "Business" ? customer : customerDetails;
  const token = localStorage.getItem('firebaseToken');

  if (!token) {
    console.log("User is not logged in");
    return;
  }

  try {
    // Save customer data to the backend (MySQL)
    const response = await axios.post('http://localhost:5000/api/customers', customerData, {
      headers: {
        'Authorization': `Bearer ${token}`,  // Attach Firebase token to Authorization header
      }
    });
    console.log("Customer details saved:", response.data);

    // Redirect to customers page
    navigate("/customers");
  } catch (error) {
    console.error("Error saving customer details:", error);
  }
};

const handleGoBack = () => {
  navigate(-1);
};


const handleGSTVerification = async () => {
  setError(null);
  setRawResponse(null);
  setBusinessDetails(null);
  setLoading(true);

  try {
    // Call the GST Verification API
    const details = await verifyGST(gstNumber);
    
    // Update the business details if GST is valid
    if (details && details.gstin) {
      setIsGstValid(true);
      setBusinessDetails({
        name: details.legal_name || "",
        tradeName: details.trade_name || "",
        gstNumber: details.gstin || "",
        status: details.current_registration_status || "Unknown",
        businessType: details.business_constitution || "",
        registrationDate: details.register_date || "",
        address: details.primary_business_address?.full_address || "",
        state: details.primary_business_address?.state_code || "",
        pincode: details.primary_business_address?.pincode || "",
      });
    } else {
      setIsGstValid(false);
      setError("Invalid GST Number");
    }
  } catch (err) {
    setError(`Verification Error: ${err.message}`);
  } finally {
    setLoading(false);
  }
};

  return (
    <div className="addcustomer-page">
      <main className="main-content">
        <header className="addcustomer-header">
          <h1>ADD-CUSTOMER</h1>
          <IconButton onClick={handleGoBack} color="white" aria-label="go back">
            <ArrowCircleLeftOutlinedIcon
              className="arrowback-addcustomer"
              fontSize="larger" /* or "small", "", "medium" */
            />

          </IconButton>
          <div className="addcustomer-search">
            <input type="text" placeholder="Search for something" />
            <PublicSharpIcon
              className="public-addcustomer"
              fontSize="larger" /* or "small", "", "medium" */

            />
            <NotificationsIcon
              className="notification-addcustomer"
              fontSize="larger" /* or "small", "", "medium" */

              aria-label="notifications"
            />
            {/* Profile Icon */}
            <img
              src="./profile.png"
              className="profile-addcustomer"
              alt="profile"

              onClick={handleProfileClick}
            />
            {/* Profile Overlay */}
            {showProfileOverlay && (
              <div className="profile-overlay">
                <div className="view-profile">
                  {/* Cancel icon now only closes the profile overlay */}
                  <CancelTwoToneIcon
                    onClick={handleGoBack}
                    sx={{
                      position: "absolute",
                      bottom: "295px",
                      right: "5px",
                      color: "red",
                      fontSize: "20px",
                      cursor: "pointer",
                    }}
                  />
                  <img src="./dp.png" alt="dp"></img>
                  <b>EZBillify</b>
                  <p>GSTIN:2964A78DL102865</p>
                  <hr />
                  <SettingsRoundedIcon
                    sx={{ position: "relative", right: "85px", top: "55px", color: "black", fontSize: "25px" }} />
                  <ManageAccountsRoundedIcon
                    sx={{ position: "relative", right: "110px", top: "20px", color: "black", fontSize: "25px" }} />
                  <PersonAddAltRoundedIcon
                    sx={{ position: "relative", right: "132px", top: "89px", color: "black", fontSize: "25px" }} />
                  <div className="dp-heading">
                    <a onClick={() => navigate("/Business")}>BUSINESS<c >DETAILS</c> </a>
                    <d onClick={() => navigate("/settings")} >SETTINGS</d>
                    <e onClick={() => navigate("/AddUsers")}>  ADD <f>USERS</f></e>
                  </div>

                </div>
              </div>
            )}
          </div>
        </header>

        <div className="addcustomer-table">
          <h4 style={{ position: "relative", bottom: "30px", fontSize: "19px" }}>Create Customer</h4>

          <RadioGroup
            row
            value={customerType}
            onChange={(e) => setCustomerType(e.target.value)}
            style={{ position: "relative", marginBottom: "20px", justifyContent: "left", bottom: "35px" }}
          >
            <FormControlLabel value="Business" control={<Radio />} label="Business" />
            <FormControlLabel value="Individual" control={<Radio />} label="Individual" />
          </RadioGroup>

          {/* Form Fields */}
          {customerType === "Business" ? (
            <>
              <div style={{ position: "relative", width: "100%", display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px", bottom: "50px" }}>
                <TextField label="Short Name" required name="name" value={customer.name} onChange={handleChange} disabled={!isGstValid} />
                <TextField label="Company Name" required name="companyName" value={customer.companyName} onChange={handleChange} disabled={!isGstValid} />

                <TextField label="Phone" type="tel" required name="phoneNumber" value={customer.phoneNumber} onChange={handleChange} disabled={!isGstValid} />

                <TextField label="Email" type="email" required name="email" value={customer.email} onChange={handleChange} disabled={!isGstValid} />
              </div>

              <h3 style={{ position: "relative", bottom: "40px" }}>Other Details</h3>
              <div style={{ position: "relative", bottom: "50px", display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>


                  <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
                    <input
                      type="text"
                      value={gstNumber}
                      onChange={(e) => setGstNumber(e.target.value)}
                      placeholder="Enter 15-digit GST Number"
                      maxLength="15"
                      className="gst-input"
                    />
                    <button
                      onClick={handleGSTVerification}
                      disabled={loading || gstNumber.length !== 15}
                      className="verify-button"
                    >
                      {loading ? "Verifying..." : "Verify GST"}
                    </button>
                  </div>

                  {/* Error Message */}
                  {error && <div style={{ color: "red" }}>{error}</div>}

                  {/* Raw Response for Debugging */}
                  {rawResponse && (
                    <div>
                      <h3>Raw API Response:</h3>
                      <pre>{rawResponse}</pre>
                    </div>
                  )}

                  {/* Display Business Details */}
                  {businessDetails && (
                    <div>
                      <h2>Business Details</h2>
                      <TextField
                        label="Name"
                        required
                        name="name"
                        value={businessDetails.name}  // Bind the value to businessDetails.name
                        onChange={(e) => setBusinessDetails({ ...businessDetails, name: e.target.value })}  // Update the state on change
                        disabled={!isGstValid}  // Disable the field based on your condition
                      />

                      <p><strong>Trade Name:</strong> {businessDetails.tradeName}</p>
                      <p><strong>GST Number:</strong> {businessDetails.gstNumber}</p>
                      <p><strong>Status:</strong> {businessDetails.status}</p>
                      <p><strong>Business Type:</strong> {businessDetails.businessType}</p>
                      <p><strong>Registration Date:</strong> {businessDetails.registrationDate}</p>
                      <h3>Address</h3>
                      <p>{businessDetails.address}</p>
                      <p><strong>State:</strong> {businessDetails.state}</p>
                      <p><strong>Pincode:</strong> {businessDetails.pincode}</p>
                    </div>
                  )}
                </div>

                <TextField label="Allowed Credit Invoices" required name="creditInvoices" value={customerDetails.creditInvoices} onChange={handleChange} disabled={!isGstValid} />
              </div>

              <h3 style={{ position: "relative", bottom: "40px", marginTop: "20px" }}>Address</h3>
              <div style={{ position: "relative", bottom: "50px", display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                <TextField label="Address Line 1" required name="addressLine1" value={customerDetails.addressLine1} onChange={handleChange} disabled={!isGstValid} />
                <TextField label="Address Line 2" name="addressLine2" value={customerDetails.addressLine2} onChange={handleChange} disabled={!isGstValid} />
                <div>
                  <label>Country</label>
                  <select
                    name="country"
                    value={customerDetails.country}
                    onChange={handleChange}
                    style={{ width: "100%", height: "55px", padding: "10px", borderRadius: "5px" }}
                    disabled={!isGstValid}
                  >
                    <option value="">Select a Country</option>
                    {Object.keys(countries).map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label>State</label>
                  <select
                    name="state"
                    value={customerDetails.state}
                    onChange={handleChange}
                    style={{ width: "100%", height: "55px", padding: "10px", borderRadius: "5px" }}
                    disabled={!isGstValid || !availableStates.length}
                  >
                    <option value="">Select a State</option>
                    {availableStates.map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>

                <TextField label="Pincode" required name="pincode" value={customerDetails.pincode} onChange={handleChange} disabled={!isGstValid} />
                <TextField label="Landmark" name="landmark" value={customerDetails.landmark} onChange={handleChange} disabled={!isGstValid} />
              </div>
            </>
          ) : (
            /**THIS IS INDIVIDUAL FORM FIELDS */
            <>
              <div style={{ position: "relative", width: "100%", display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px", bottom: "50px" }}>
              <TextField label="Customer Name" required name="name" value={customerType === "Business" ? customer.name : customerDetails.name} onChange={handleChange} />
<TextField label="Phone" required name="phoneNumber" value={customerType === "Business" ? customer.phoneNumber : customerDetails.phoneNumber} onChange={handleChange} />
<TextField label="Email" required name="email" value={customerType === "Business" ? customer.email : customerDetails.email} onChange={handleChange} />

              </div>

              <h3 style={{ position: "relative", bottom: "40px" }}>Other Details</h3>
              <div style={{ position: "relative", bottom: "50px", display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                <TextField label="Allowed Credit Invoices" required name="creditInvoices" value={customerDetails.creditInvoices} onChange={handleChange} />
              </div>

              <h3 style={{ position: "relative", bottom: "40px", marginTop: "20px" }}>Address</h3>
              <div style={{ position: "relative", bottom: "50px", display: "grid", gridTemplateColumns: "1fr 1fr", gap: "20px" }}>
                <TextField label="Address Line 1" required name="address" value={customerDetails.address} onChange={handleChange} />
                <TextField label="Address Line 2" name="addressLine2" value={customerDetails.addressLine2} onChange={handleChange} />
                <div>
                  <label>Country</label>
                  <select
                    name="country"
                    value={customerDetails.country}
                    onChange={handleChange}
                    style={{ width: "100%", height: "55px", padding: "10px", borderRadius: "5px" }}
                  >
                    <option value="">Select a Country</option>
                    {Object.keys(countries).map((country) => (
                      <option key={country} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                </div>

                <div>
                  <label>State</label>
                  <select
                    name="state"
                    value={customerDetails.state}
                    onChange={handleChange}
                    style={{ width: "100%", height: "55px", padding: "10px", borderRadius: "5px" }}
                  >
                    <option value="">Select a State</option>
                    {availableStates.map((state, index) => (
                      <option key={index} value={state}>
                        {state}
                      </option>
                    ))}
                  </select>
                </div>
                <TextField label="Pincode" name="pincode" value={customerDetails.pincode} onChange={handleChange} />
                <TextField label="Landmark" name="landmark" value={customerDetails.landmark} onChange={handleChange} />
              </div>

            </>

          )}


          <div style={{ position: "relative", bottom: "650px", display: "flex", justifyContent: "right", gap: "10px" }}>
            <Button variant="contained" onClick={handleSave} style={{ background: "black", width: "120px", height: "30px", fontSize: "15px", boxShadow: "none", }}>
              Save
            </Button>
            <Button variant="outlined" onClick={handleGoBack} style={{ background: "white", width: "120px", height: "30px", fontSize: "15px", boxShadow: "none", borderColor: "black", color: "black" }}>
              Close
            </Button>
          </div>
        </div>
      </main>
    </div>
  );
};

export default Addcustomer;
